nav.main .container-fluid {
  height: 610px;
  background: url("/images/jumping-lowlight.jpg");
  background-size: cover;
  background-position: top;
}

nav.main .container-fluid {
  padding-left: 0px;
}

nav.main .navbar-nav {
  background-color: #E7E7E7;
  color: #FF6600;
  margin: -0.5px -15px;
}

nav.main .navbar-nav li > a {
  padding-left: 2em;
}

nav.main .navbar-default .navbar-toggle:focus, nav.main .navbar-default .navbar-toggle:hover {
  background-color: none;
}

nav.main .navbar-default .navbar-nav > li > a {
  padding-left: 35px;
}

nav.main .navbar-default .navbar-toggle span.icon-bar {
  background-color: white;
}

nav.main .navbar-toggle {
  margin-bottom: 17px;
}

.navbar {
  margin-bottom: 0px;
}

nav.header .container-fluid {
  background-color: #FF6600;
}

nav.header .container-fluid a.navbar-brand {
  color: white;
}

nav.header .container-fluid .navbar-collapse .navbar-nav > li > a {
  color: white;
}

a.navbar-brand img {
  padding-left: 1em;
  margin-top: -0.5em;
}

.main-form {
  position: absolute;
  z-index: 1;
  margin-top: -480px;
}

.main-form h2 {
  color: white;
  margin-top: 232px;
  font-size: 34px;
  text-align: center;
}

.col-md-10 h2 {
  text-align: left;
}

.form-row {
  margin-top: -12em;
  margin-left: 1em;
}

.form-row div > input.button {
  text-align: center;
  color: white;
  background-color: #FF6600 !important;
  border: none;
  text-align: center;
  border-radius: 4px;
}

.form-row .col-xs-12 {
  margin-bottom: 1em;
  padding-left: 0px;
}

.form-row .col-sm-4 span.input-group-addon {
  padding: 9px 12px;
  position: absolute;
  width: auto;
}

.form-row .col-sm-4 input#l.form-control {
  text-align: center;
}

.form-row .col-sm-4 input#q.form-control {
  text-align: center;
}

form.sign-up {
  z-index: 1;
}

div.icons {
  background-color: #E7E7E7;
}

div.icons div.bottom-headline h1.blurb {
  padding: 1em;
  font-size: 32px;
  margin-top: 30px;
  margin-bottom: 40px;
}

div.icons div.icon-box {
  margin-bottom: 10em;
}

div.icons div.icon-box a.icon {
  text-decoration: none;
  color: black;
  text-align: center;
}

div.icons div.icon-box a.icon .fa {
  color: #FF6600;
}

div.icons div.icon-box a.icon h2.icon-title {
  color: #FF6600;
}

div.icons div.icon-box a.icon h2.icon-title-sign-up {
  color: #FF6600;
}

div.footer {
  background-color: #3D3D3D;
  color: #B3B3B3;
  padding: 1em;
}

div.footer a {
  text-decoration: none;
  color: #B3B3B3;
}

div.footer .col-xs-10 {
  padding-top: 1em;
}

div.footer div > p {
  font-size: 12px;
  padding-top: 1em;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  border-top: solid 1px black;
  color: #B3B3B3;
}

div.contact {
  margin: 2em 1em 13em;
  padding: 1em;
}

div.contact h1 {
  color: #FF6600;
  margin-bottom: 1em;
}

div.contact p > a {
  color: #FF6600;
}

div.contact form > table {
  width: 100%;
}

div.contact form > table td > div.field > p input#name {
  border: solid 1px #ECECEC;
  padding: .5em;
  border-radius: 5px;
  width: 100%;
}

div.contact form > table td > div.field > p input#email {
  border: solid 1px #ECECEC;
  padding: .5em;
  border-radius: 5px;
  width: 100%;
}

div.contact form > table td > div.field > p input#subject {
  border: solid 1px #ECECEC;
  padding: .5em;
  border-radius: 5px;
  width: 100%;
}

div.contact form > table td > div.field > p textarea#message {
  height: 10em;
  border: solid 1px #ECECEC;
  padding: .5em;
  border-radius: 5px;
  width: 100%;
}

div.contact form > table td > div.field > p input#send.button.is-primary {
  color: white;
  background-color: #FF6600;
  border: none;
  padding: .5em;
  border-radius: 5px;
}

div.unsubscribe {
  margin: 2em 1em 32em;
  border-radius: 10px;
  padding: 1em;
  width: 90%;
}

div.unsubscribe h1 {
  color: #FF6600;
  margin-bottom: 1em;
}

div.unsubscribe #contentsec {
  padding-bottom: 10px;
  margin-bottom: -40px;
}

div.unsubscribe #contentsec p {
  background-color: white;
  padding: .5em 1em .5em;
}

div.unsubscribe #contentsec form.unsubscribe {
  margin: .4em .4em 4em;
  border: solid 1px #FF6600;
}

div.unsubscribe #contentsec form.unsubscribe label {
  background-color: white;
  padding-left: 1em;
  padding-top: 1em;
}

div.unsubscribe #contentsec form.unsubscribe input.button {
  background-color: #FF6600;
  border-radius: 6px;
  margin-left: 1.3em;
  margin-top: .5em;
  margin-bottom: 1em;
  color: white;
}

div.unsubscribe #contentsec form.unsubscribe input.text {
  border: solid 1px #FF6600;
  border-radius: 6px;
  width: 85%;
  padding: .5em;
  margin-top: -8px;
  margin-left: 1.3em;
}

div.unsubscribe-success {
  margin: 2em 1em 10em;
  padding: 1em;
}

div.unsubscribe-success div > div > div > div > h2:nth-child(1) {
  color: #FF6600;
}

div.unsubscribe-success div > div > div > div > h2:nth-child(13) {
  color: #FF6600;
}

div.unsubscribe-success div > div > div > div > form > div.Keywords > input[type="text"] {
  border: solid 1px black;
}

div.unsubscribe-success div > div > div > div > form > div.Location > input[type="text"] {
  border: solid 1px black;
}

div.unsubscribe-success div > div > div > div > form > div.Submit > input[type="submit"] {
  color: white;
  background-color: #FF6600;
  border: none;
  padding: .5em;
  border-radius: 5px;
}

div.sign-up {
  margin: 4em 1em 24em;
  padding: 1em;
}

div.sign-up h1 {
  color: #FF6600;
  margin-bottom: 1em;
}

div.sign-up #contentsec form.signup input {
  border: solid 1px #DADADA;
  padding: .5em;
  width: 100%;
  border-radius: 6px;
  margin-bottom: .5em;
}

div.sign-up #contentsec form.signup p.check input.terms {
  width: 10%;
}

div.sign-up #contentsec form.signup p.check a {
  color: #FF6600;
}

div.sign-up #contentsec form.signup .button {
  background-color: #FF6600;
  color: white;
}

div.email {
  margin: 4em 1em 26em;
  padding: 1em;
}

div.email h1 {
  color: #FF6600;
  margin-bottom: 1em;
}

div.email div > div > div > div > a {
  color: #FF6600;
}

div.email div > div > div > p:nth-child(5) > a {
  color: #FF6600;
}

div.terms {
  margin: 2em 1em 4em;
  padding: 1em;
}

div.terms h1 {
  color: #FF6600;
  margin-bottom: 1em;
}

div.terms #contentsec > div:nth-child(47) > h4 {
  color: #FF6600;
}

div.post {
  margin: 2em 1em 12em;
  padding: 1em;
}

div.post h1 {
  color: #FF6600;
  margin-bottom: 1em;
}

div.post a.pagination {
  color: #FF6600;
  margin-top: 1em;
}

a.post-links {
  text-decoration: none;
}

a.post-links div.individual-post {
  border-bottom: solid 3px #FF6600;
}

a.post-links p.title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 1em;
  color: #3D3D3D;
}

a.post-links p.summary {
  color: #3D3D3D;
}

a.post-links button.button {
  color: #FF6600;
  padding-bottom: 1em;
  background-color: white;
  border: none;
}

div.blog-page {
  margin-bottom: 16em;
}

div.blog-page h2 {
  margin-top: 2em;
  color: #FF6600;
}

div.blog-page a.back-to-posts {
  color: #FF6600;
  text-decoration: none;
}

div.privacy {
  margin: 2em 1em 4em;
  padding: 1em;
}

div.privacy h1 {
  color: #FF6600;
  margin-bottom: 1em;
}

div.privacy #contentsec > div:nth-child(31) > p > a {
  color: #FF6600 !important;
}

div.privacy #contentsec > div:nth-child(21) > p > a {
  color: #FF6600 !important;
}

@media (min-width: 768px) {
  div.main-form h2 {
    margin-top: 266px;
  }
}

@media (min-width: 992px) {
  a.navbar-brand img {
    padding-left: 7em;
  }
  nav.header .container-fluid a.navbar-brand {
    padding-left: 7em;
  }
}

@media (min-width: 1200px) {
  a.navbar-brand img {
    padding-left: 9em;
  }
  nav.header .container-fluid a.navbar-brand {
    padding-left: 9em;
  }
}

@media (min-width: 1800px) {
  a.navbar-brand img {
    padding-left: 18em;
  }
  nav.header .container-fluid a.navbar-brand {
    padding-left: 18em;
  }
  .form-row {
    margin-top: -19em;
  }
  div.main-form h2 {
    margin-top: 155px;
  }
}
