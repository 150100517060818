nav.main{
  .container-fluid{
  height: 610px;
  background:url("/images/jumping-lowlight.jpg");
  background-size: cover;
  background-position:top;
  }

  .container-fluid{
    padding-left:0px;
  }
  .navbar-nav{
    background-color: #E7E7E7;
    color: #FF6600;
    margin:-0.5px -15px;
    li>a{
      padding-left:2em;
    }
  }

  .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover{
    background-color: none;
  }

  .navbar-default .navbar-nav>li>a{
    padding-left: 35px;
  }

  .navbar-default .navbar-toggle span.icon-bar{
    background-color:white;
  }
  .navbar-toggle{
    margin-bottom: 17px;
  }
}

.navbar{
  margin-bottom:0px;
}


// second navbar
nav.header{
  .container-fluid{
    background-color:#FF6600;
    a.navbar-brand{
      color:white;
    }
    .navbar-collapse .navbar-nav>li>a{
      color:white;
    }
  }
}

a.navbar-brand{
  img{
  padding-left: 1em;
  margin-top:-0.5em;
  }
}

.main-form{
  position:absolute;
  z-index:1;
  margin-top:-480px;
  h2{
    color:white;
    margin-top: 232px;
    font-size:34px;
    text-align: center;
  }
}
.col-md-10{
  h2{
    text-align: left;
  }
}

.form-row{
  margin-top: -12em;
  margin-left: 1em;
    div>input.button{
      text-align: center;
      color:white;
      background-color:#FF6600 !important;
      border:none;
      text-align: center;
      border-radius: 4px;
    }
  .col-xs-12{
    margin-bottom:1em;
    padding-left:0px;
  }
  .col-sm-4{
    span.input-group-addon{
      padding:9px 12px;
      position: absolute;
      width:auto;
    }
    input#l.form-control{
        text-align: center;
    }
    input#q.form-control{
      text-align: center;
    }
  }
}

form.sign-up{
  z-index:1;
}


div.icons{
  background-color:#E7E7E7;
    div.bottom-headline{
      h1.blurb{
        padding:1em;
        font-size:32px;
        margin-top: 30px;
        margin-bottom: 40px;
      }
    }

    div.icon-box{
      margin-bottom:10em;

          a.icon{
            text-decoration: none;
            color:black;
            text-align: center;
          .fa{
            color: #FF6600;
          }
          h2.icon-title{
            color: #FF6600;
          }
          h2.icon-title-sign-up{
            color: #FF6600;
          }
        }
      }
}

div.footer{
  background-color:#3D3D3D;
  color:#B3B3B3;
  padding:1em;
  a{
    text-decoration: none;
    color:#B3B3B3;
  }
  .col-xs-10{
    padding-top:1em;
  }
  div > p{
    font-size:12px;
    padding-top:1em;
    margin-top:1em;
    margin-left: 1em;
    margin-right: 1em;
    border-top: solid 1px black;
    color:#B3B3B3;
  }
}

div.contact{
        margin: 2em 1em 13em;
        padding: 1em;
        h1{
          color: #FF6600;
          margin-bottom:1em;
        }
        p > a{
            color: #FF6600;
        }
        form > table{
              width: 100%;
              td > div.field > p{
                input#name{
                  border: solid 1px #ECECEC;
                  padding: .5em;
                  border-radius: 5px;
                  width: 100%;
                }
                input#email{
                  border: solid 1px #ECECEC;
                  padding: .5em;
                  border-radius: 5px;
                  width: 100%;
                }
                input#subject{
                  border: solid 1px #ECECEC;
                  padding: .5em;
                  border-radius: 5px;
                  width: 100%;
                }
                textarea#message{
                  height: 10em;
                  border: solid 1px #ECECEC;
                  padding: .5em;
                  border-radius: 5px;
                  width: 100%;
                }
                input#send.button.is-primary{
                  color:white;
                  background-color: #FF6600;
                  border: none;
                  padding: .5em;
                  border-radius: 5px;
                }
              }
            }
    }

  div.unsubscribe{
      margin: 2em 1em 32em;
      border-radius: 10px;
      padding: 1em;
      width:90%;
      h1{
        color: #FF6600;
        margin-bottom:1em;
      }
      #contentsec{
        padding-bottom: 10px;
        margin-bottom: -40px;
      p{
        background-color: white;
        padding: .5em 1em .5em;
        }
      form.unsubscribe{
        margin:.4em .4em 4em;
        border: solid 1px #FF6600;
      label{
        background-color: white;
        padding-left: 1em;
        padding-top: 1em;
        }
      input.button{
        background-color:#FF6600;
        border-radius: 6px;
        margin-left: 1.3em;
        margin-top: .5em;
        margin-bottom: 1em;
        color: white;
        }
      input.text{
        border: solid 1px #FF6600;
        border-radius: 6px;
        width: 85%;
        padding: .5em;
        margin-top: -8px;
        margin-left: 1.3em;
        }
      }
    }
    }

  div.unsubscribe-success{
        margin: 2em 1em 10em;
        padding: 1em;
      div > div > div > div > h2:nth-child(1){
        color:#FF6600;
      }
      div > div > div > div > h2:nth-child(13){
        color:#FF6600;
      }
      div > div > div > div > form > div.Keywords > input[type="text"]{
        border:solid 1px black;
      }
      div > div > div > div > form > div.Location > input[type="text"]{
      border:solid 1px black;
      }
      div > div > div > div > form > div.Submit > input[type="submit"]{
        color:white;
        background-color: #FF6600;
        border: none;
        padding: .5em;
        border-radius: 5px;
      }
    }

    div.sign-up{
        margin: 4em 1em 24em;
        padding: 1em;
        h1{
          color: #FF6600;
          margin-bottom:1em;
        }
        #contentsec{
              form.signup{
                input{
                  border: solid 1px #DADADA;
                  padding: .5em;
                  width: 100%;
                  border-radius: 6px;
                  margin-bottom: .5em;
                }
                p.check{
                  input.terms{
                    width: 10%;
                  }
                  a{
                    color:#FF6600;
                  }
                }
                .button{
                  background-color: #FF6600;
                  color: white;
                }
              }
            }
          }


  div.email{
      margin: 4em 1em 26em;
      padding: 1em;
      h1{
        color: #FF6600;
        margin-bottom:1em;
      }
      div > div > div > div > a{
          color:#FF6600;
      }
      div > div > div > p:nth-child(5) > a{
          color:#FF6600;
       }
    }

  div.terms{
      margin: 2em 1em 4em;
      padding: 1em;
      h1{
        color: #FF6600;
        margin-bottom:1em;
      }
      #contentsec > div:nth-child(47) > h4{
        color:#FF6600;
      }
    }

    div.post{
        margin: 2em 1em 12em;
        padding: 1em;
        h1{
          color: #FF6600;
          margin-bottom:1em;
        }
        a.pagination{
          color: #FF6600;
          margin-top:1em;
        }
      }

    a.post-links{
      text-decoration:none;
          div.individual-post{
            border-bottom: solid 3px #FF6600;
          }
          p.title{
            font-size: 20px;
            font-weight: bold;
            margin-top:1em;
            color:#3D3D3D;
          }
          p.summary{
            color:#3D3D3D;
          }
          button.button {
            color: #FF6600;
            padding-bottom: 1em;
            background-color: white;
            border:none;
          }
      }

    div.blog-page{
      margin-bottom:16em;
      h2{
        margin-top: 2em;
        color: #FF6600;

      }
      a.back-to-posts{
        color: #FF6600;
        text-decoration: none;
      }
    }

  div.privacy{
    margin: 2em 1em 4em;
    padding: 1em;
    h1{
      color: #FF6600;
      margin-bottom:1em;
    }
    #contentsec > div:nth-child(31) > p > a{
      color:#FF6600 !important;
    }
    #contentsec > div:nth-child(21) > p > a{
      color:#FF6600 !important;
    }
  }

  @media(min-width: 768px){
    div.main-form{
      h2{
        margin-top:266px;
      }
    }

  }

  @media(min-width: 992px){
    a.navbar-brand img {
      padding-left:7em;
    }
    nav.header .container-fluid a.navbar-brand{
      padding-left: 7em;
    }

  }

  @media(min-width: 1200px){
    a.navbar-brand img {
      padding-left:9em;
    }
    nav.header .container-fluid a.navbar-brand{
      padding-left: 9em;
    }

  }

  @media(min-width: 1800px){
    a.navbar-brand img {
      padding-left:18em;
    }
    nav.header .container-fluid a.navbar-brand{
      padding-left: 18em;
    }
    .form-row{
      margin-top:-19em;
    }
    div.main-form h2{
      margin-top:155px;
    }



  }
